// import api from '@api'
import axios from 'axios'

export function getLocaleDataApi(locale, moduleNames = []) {
  return axios
    .post(`/api/public/frontend/lang/data/${locale}`, moduleNames)
    .then((response) => response.data)
    .catch((e) => e)
  // return api
  //   .post('/public/frontend/lang/data', moduleNames)
}
